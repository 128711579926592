import React from "react";
import { collection, getDocs, query, where } from "@firebase/firestore";
import { Grid, MenuItem, TextField, Button } from "@mui/material";
import { useState } from "react";
import { toast } from "react-toastify";
import { COUNTRIES, STATES } from "../../../../utils/constants";

const PersonalInformation = ({ nextStep, data, setData, db }) => {
  const [selected, setSelected] = useState();

  const handleNext = async () => {
    if (!data?.nombre?.trim() || !data?.email?.trim() || !data?.telefono) {
      return toast.info("Favor de llenar todos los campos");
    }

    // Si no existe un registro, continuar al siguiente paso
    nextStep();
  };

  return (
    <Grid component="form" direction="column" container spacing={2}>
      <Grid item>
        <TextField
          autoFocus
          fullWidth
          variant="standard"
          name="nombre"
          label="Nombre completo"
          autoComplete="given-name"
          value={data?.nombre || ""}
          onChange={(e) => setData({ ...data, nombre: e.target.value })}
        />
      </Grid>
      <Grid item>
        <TextField
          fullWidth
          variant="standard"
          name="empresa"
          label="Empresa (Opcional)"
          value={data?.empresa || ""}
          onChange={(e) => setData({ ...data, empresa: e.target.value })}
        />
      </Grid>
      <Grid item>
        <TextField
          fullWidth
          variant="standard"
          name="cargo"
          label="Cargo (Opcional)"
          value={data?.cargo || ""}
          onChange={(e) => setData({ ...data, cargo: e.target.value })}
        />
      </Grid>
      <Grid item>
        <TextField
          select
          fullWidth
          variant="standard"
          label="País"
          color="primary"
          name="evento"
          value={data?.pais || ""}
          onChange={(e) => {
            const filtered = STATES.filter(
              (item) => item.id_country === e.target.value
            );
            setSelected(filtered);
            setData({ ...data, pais: e.target.value });
          }}
        >
          <MenuItem value="" disabled selected>
            Seleccione un país
          </MenuItem>
          {COUNTRIES.map((e, key) => (
            <MenuItem key={key} value={e.id}>
              {e.name}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item>
        <TextField
          select
          fullWidth
          variant="standard"
          label="Estado"
          color="primary"
          name="evento"
          value={data?.estado || ""}
          onChange={(e) => setData({ ...data, estado: e.target.value })}
        >
          <MenuItem value="" disabled selected>
            Seleccione un estado
          </MenuItem>
          {selected?.map((e, key) => (
            <MenuItem key={key} value={e?.name}>
              {e?.name}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item>
        <TextField
          fullWidth
          variant="standard"
          name="email"
          autoComplete="email"
          type="email"
          label="Correo electrónico"
          value={data?.email || ""}
          onChange={(e) => setData({ ...data, email: e.target.value })}
        />
      </Grid>
      <Grid item>
        <TextField
          inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
          type="text"
          fullWidth
          variant="standard"
          name="telefono"
          label="Teléfono"
          value={data?.telefono || ""}
          onChange={(e) =>
            setData({ ...data, telefono: Number(e.target.value) })
          }
        />
      </Grid>
      <Grid item>
        <TextField
          fullWidth
          variant="standard"
          id="facebook"
          label="Facebook (Opcional)"
          name="facebook"
          type="url"
          value={data?.facebook || ""}
          onChange={(e) => setData({ ...data, facebook: e.target.value })}
        />
      </Grid>
      <Grid item>
        <TextField
          fullWidth
          variant="standard"
          id="instagram"
          label="Instagram (Opcional)"
          name="instagram"
          type="url"
          value={data?.instagram || ""}
          onChange={(e) => setData({ ...data, instagram: e.target.value })}
        />
      </Grid>
      <Grid item>
        <TextField
          fullWidth
          variant="standard"
          id="twitter"
          label="X (Opcional)"
          name="twitter"
          type="url"
          value={data?.twitter || ""}
          onChange={(e) => setData({ ...data, twitter: e.target.value })}
        />
      </Grid>
      <Grid item>
        <Button variant="contained" color="primary" onClick={handleNext}>
          Siguiente
        </Button>
      </Grid>
    </Grid>
  );
};

export default PersonalInformation;
