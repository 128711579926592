import { Button, Grid, Typography } from "@mui/material";
import moment from "moment";
import { QRCodeSVG } from "qrcode.react";
import React, { useRef } from "react";
import { ComponentToPrint } from "../ComponentToPrint";
import ReactToPrint from "react-to-print";

const Congratulations = ({ data }) => {
  const componentRef = useRef();
  const route = "https://festivaldelchocolate.mx/validar";

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography>
          Gracias por registrarte, esperamos tu asistencia!
        </Typography>
      </Grid>

      <Grid item container xs={12} justifyContent="center">
        <Grid
          item
          xs
          container
          spacing={3}
          direction="column"
          justifyContent="center"
        >
          <Grid item>
            <Typography color="primary" variant="h5" fontWeight={600}>
              ¡Hola!
            </Typography>
          </Grid>
          <Grid item textAlign="justify">
            <Typography>
              <span style={{ fontWeight: "bold" }}>{data.nombre}</span>, has
              completado tu registro al evento{" "}
              <span style={{ fontWeight: "bold" }}>{data.evento}</span>, la cual
              se llevará a cabo en el horario que seleccionaste
            </Typography>
          </Grid>
          <Grid item textAlign="center">
            <Typography>
              Por favor, al llegar muestra tu pase de acceso.
            </Typography>
          </Grid>
          <Grid item textAlign="center">
            <Typography fontWeight={600}>Fecha y hora del evento:</Typography>
            <Typography>
              {`${moment(data.inicio.toDate()).format("dddd D MMMM, hh:mm a")}`}
            </Typography>
          </Grid>
          <Grid item textAlign="center">
            {
              <QRCodeSVG
                value={`${route}/?m=${data.email}?ev=${data.evento}`}
              />
            }
          </Grid>
          <Grid item textAlign="justify">
            <Typography color="GrayText">
              Está hoja es tu pase de acceso a la Cata seleccionado en el
              horario que escogiste previamente. En caso de perderlo, podrás
              volver a descargar el documento desde la sección de inicio de esta
              página.
            </Typography>
          </Grid>
        </Grid>
        <Grid container justifyContent="center" padding={5}>
          <ReactToPrint
            trigger={() => (
              <Button color="primary" variant="contained">
                Descargar
              </Button>
            )}
            content={() => componentRef.current}
          />
        </Grid>

        <div style={{ display: "none" }}>
          <ComponentToPrint
            ref={componentRef}
            data={data}
            qr={
              <QRCodeSVG
                value={`${route}/?m=${data.email}?ev=${data.evento}`}
              />
            }
          />
        </div>
      </Grid>
    </Grid>
  );
};

export default Congratulations;
