import {
  collection,
  getDocs,
  onSnapshot,
  query,
  where,
} from "@firebase/firestore";
import {
  Button,
  Card,
  Grid,
  MenuItem,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import moment from "moment";
import { Room, RoomOutlined } from "@mui/icons-material";

const EventInformation = ({ nextStep, prevStep, data, setData, db }) => {
  const [speaker, setSpeaker] = useState("");
  const [selected, setSelected] = useState();
  const [selectedDate, setSelectedDate] = useState();
  const [catas, setCatas] = useState();
  const theme = useTheme();

  useEffect(() => {
    const unsub = onSnapshot(collection(db, "programa"), (querySnapshot) => {
      const result = [];
      querySnapshot.forEach((doc) => {
        const activity = doc.data();
        activity.id = doc.id;
        result.push({ ...activity });
      });
      setCatas(result);
    });
    return unsub;
  }, []);

  const handleNext = async () => {
    setData({ ...data, salon: speaker.salon, inicio: speaker.inicio });
    if (!selectedDate || !data?.evento) {
      return toast.info("Seleccione la fecha y cata");
    }

    const registroRef = collection(db, "reservacionCatas");
    const q = query(registroRef, where("evento", "==", data.evento));
    const querySnapshot = await getDocs(q);

    console.log(querySnapshot.size);

    if (querySnapshot.size >= speaker.stock) {
      return toast.info(
        "De momento todos los lugares ya se encuentran ocupados para esta cata."
      );
    }

    nextStep();
  };

  return (
    <Grid component="form" container spacing={3}>
      <Grid item xs={12} md={6}>
        <TextField
          select
          fullWidth
          label="Fecha"
          color="primary"
          name="evento"
          value={selectedDate || ""}
          onChange={(e) => {
            const filtered = catas
              .filter(
                (item) => item.salon === "Cacao" || item.salon === "Tabá Cao"
              )
              .filter((item) => item.stock)
              .filter((item) => {
                const value = e.target.value;
                return moment(item.inicio.toDate(), "DD/MM/YYYY").isSame(
                  moment(value, "DD/MM/YYYY"),
                  "date"
                );
              });
            setSelected(filtered);
            setSelectedDate(e.target.value);
            setData({ ...data, cataDate: e.target.value });
            setSpeaker();
          }}
        >
          <MenuItem value="" disabled selected>
            Seleccione una fecha
          </MenuItem>
          {[
            "15/11/2023",
            "16/11/2023",
            "17/11/2023",
            "18/11/2023",
            "19/11/2023",
          ].map((date, key) => (
            <MenuItem key={key} value={date}>
              {moment(date, "DD/MM/YYYY").format("DD [de] MMMM")}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          select
          fullWidth
          label="Cata"
          color="primary"
          name="evento"
          value={data?.evento || ""}
          onChange={(e) => {
            const selectedCata = catas.find(
              (cata) => cata.nombre === e.target.value
            );
            setData({ ...data, evento: e.target.value });
            setSpeaker(selectedCata);
          }}
        >
          <MenuItem value="" disabled selected>
            Seleccione una Cata
          </MenuItem>
          {selected?.map((event, key) => (
            <MenuItem key={key} value={event.nombre}>
              {event.nombre}
            </MenuItem>
          ))}
        </TextField>
      </Grid>

      {speaker ? (
        <Grid item container>
          <Card
            style={{
              backgroundColor: theme.palette.primary.main,
              padding: 25,
              color: "white",
            }}
          >
            <Grid item container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="body2">
                  {moment(speaker.inicio.toDate()).format("dddd D MMMM")} •{" "}
                  {moment(speaker.inicio.toDate()).format("HH:mm")} -{" "}
                  {moment(speaker.fin.toDate()).format("HH:mm") + " hrs."}
                </Typography>
              </Grid>
              <Grid item container xs={12}>
                <Grid item xs={12}>
                  <Typography variant="h6">{speaker.nombre}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="subtitle2">
                    {speaker.ponente || ""}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item container alignItems="center" xs={12}>
                <Grid item>
                  <RoomOutlined />
                </Grid>
                <Grid item xs>
                  <Typography>
                    {speaker.salon === "Chocolate"
                      ? "Salón Chocolate - Nave Chocolate"
                      : speaker.salon === "Tabá Cao" ||
                        speaker.salon === "Cacao"
                      ? "Salón Cacao - Nave Chocolate"
                      : speaker.salon === "Cavaco"
                      ? "Salón Cavacao - Nave Chocolate"
                      : speaker.salon === "Sabor a tabasco"
                      ? "Sabor a Tabasco - Área Gastronómica"
                      : speaker.salon === "Área Infantil"
                      ? "Área Infantil - Nave Cacao"
                      : speaker.salon}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      ) : null}

      <Grid item xs={12}>
        <Button
          sx={{ marginRight: 2 }}
          variant="outlined"
          color="primary"
          onClick={prevStep}
        >
          Atrás
        </Button>
        <Button variant="contained" color="primary" onClick={handleNext}>
          Siguiente
        </Button>
      </Grid>
    </Grid>
  );
};

export default EventInformation;
